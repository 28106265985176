import React, { useEffect, useState } from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Typography } from "components/typography"
import { CircularProgress, Stack } from "@mui/material"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { providerImage } from "components/survey/utils.js"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import Collapse from "@mui/material/Collapse"
import { navigate } from "gatsby"
import * as Clickable from "components/clickable"
import * as styles from "./style.module.scss"
import { apis as API } from "@sog/sdk"
import store from "state/store"
import { storeSurveyId } from "state/actions"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { UserNotification, UserNotificationType } from "../notifications"
import { useAuth } from "state/AuthContext"
import { EventSummary, EventSummaryType } from "../event-history"

type PropertyType = {
  loans: any[]
  [key: string]: any
}

const Dashboard = () => {
  const { userDetails, isRefetching, error, logoutUser, refetchUserDetails } = useAuth()

  const isWide = useMediaQuery("(min-width:768px)")
  const [isExpand, setIsExpand] = useState<any>({})
  const [isOpen, setIsOpen] = useState<any>({})
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  // const [notificationLoadStatus, setNotificationLoadStatus] = useState<"loading"|"error"|"success">("loading")
  // const [topNotifications, setTopNotifications] = useState<UserNotificationType[]>([])
  // const [newNotificationCount, setNewNotificationCount] = useState(0)
  // const [eventHistoryLoadStatus, setEventHistoryLoadStatus] = useState<"loading"|"error"|"success">("loading")
  // const [eventHistory, setEventHistory] = useState<EventSummaryType[]>([])
  const [propertiesLoadStatus, setPropertiesLoadStatus] = useState<"loading"|"error"|"success">("loading")
  const [properties, setProperties] = useState<PropertyType[]>([])
  const [hasActiveEvent, setHasActiveEvent] = useState(true)

  const userDetailsLoadStatus = React.useMemo(
    () => (isRefetching ? "loading" : userDetails ? "success" : error ? "error" : "loading"),
    [userDetails, isRefetching, error]
  )
  const offers = React.useMemo(() => (userDetails?.viewed ? userDetails.viewed : []), [userDetails])
  // const retentionEvents = React.useMemo(() => (userDetails?.retentionEvents ? userDetails.retentionEvents : []), [userDetails])
  const lastUpdateTimestamp = React.useMemo(() => userDetails?.product?.timestamp ? userDetails.product.timestamp : null, [userDetails])

  useEffect(() => {
    API.getUserProperties((data) => {
      setProperties(data)
      setPropertiesLoadStatus("success")
    }, (error) => {
      setPropertiesLoadStatus("error")
    })
    // API.getUserNotifications((data) => {
    //   if (data.items && data.items.length > 0) {
    //     setTopNotifications(data.items as UserNotificationType[])
    //     setNewNotificationCount(data.count || data.items.length)
    //     setNotificationLoadStatus("success")
    //   } else {
    //     API.getUserNotifications((data) => {
    //       setTopNotifications(data.items as UserNotificationType[])
    //       setNewNotificationCount(0)
    //       setNotificationLoadStatus("success")
    //     }, (error) => {
    //       setNotificationLoadStatus("error")
    //     }, false, 5)
    //   }
    // }, (error) => {
    //   setNotificationLoadStatus("error")
    // }, true, 5)
    // API.getEventHistory((data) => {
    //   setEventHistory(data.items as EventSummaryType[])
    //   setEventHistoryLoadStatus("success")
    // }, (error) => {
    //   setEventHistoryLoadStatus("error")
    // }, 3)
  }, [])

  const updateIsExpand = (i: number, j: number) => {
    const newIsExpand = { ...isExpand }
    if (!(i in newIsExpand)) {
      newIsExpand[i] = {}
    }
    if (!(j in newIsExpand[i])) {
      newIsExpand[i][j] = false
    }
    newIsExpand[i][j] = !newIsExpand[i][j]
    setIsExpand(newIsExpand)
  }

  const handleSignout = () => {
    logoutUser({}, responseJson => {
      navigate("/")
    })
  }

  const handleEditProperty = (i: number) => {
    navigate("/user/update-your-property", {
      state: {
        properties: properties,
        currentIndex: i,
      },
    })
  }

  const handleRemoveProperty = (i: number) => {
    const newIsOpen = { ...isOpen }
    newIsOpen[i] = true
    setIsOpen(newIsOpen)
  }

  const handleClose = (i: number) => {
    const newIsOpen = { ...isOpen }
    newIsOpen[i] = false
    setIsOpen(newIsOpen)
  }

  const removeProperty = (i: number) => {
    const newProperties = [...properties]
    newProperties.splice(i, 1)
    API.updateUserProducts({ properties: newProperties }, (response: any) => {
      handleClose(i)
      refetchUserDetails()
    })
  }

  const acceptOffer = (id: string) => {
    API.acceptHLOffer({ "event_id": id }, (response: any) => {
      refetchUserDetails()
    })
  }

  const Field = ({ label, value, width }: { label: string; value: string; width: string }) => {
    return (
      <div style={{ width: width, marginBottom: "10px" }}>
        <Typography.P comp="x-small">{label}</Typography.P>
        <Typography.P comp="hl-medium-small" fontColor="#202020">
          {value}
        </Typography.P>
      </div>
    )
  }

  const DetailsComponent = () => {
    if (!userDetails) {
      return <CircularProgress style={{ color: "#00AFC5" }} />
    }
    return (
      <div className={styles.componentDiv}>
        <Stack direction="row" justifyContent="space-between">
          <Typography.P comp="medium-bold" fontColor="#202020">
            {userDetails.firstName + " " + userDetails.lastName}
          </Typography.P>
          {/* <Typography.P comp="hl-medium-small" fontColor="#00AFC5">Edit</Typography.P> */}
        </Stack>
        <div style={{ marginBlock: "8px" }}>
          <Typography.P comp="profile-small" fontColor="#202020">
            {userDetails.email}
          </Typography.P>
        </div>
        <Typography.P comp="profile-small" fontColor="#202020">
          {userDetails.mobileNumber}
        </Typography.P>
      </div>
    )
  }

  const ProductsComponent = () => {
    let numOfHomes = 0
    let numOfInvestments = 0

    properties.map((property, i) => {
      if (property.purpose === "OWNER_OCCUPIED") {
        numOfHomes += 1
        property.id = numOfHomes
      } else {
        numOfInvestments += 1
        property.id = numOfInvestments
      }
    })

    return (
      <div>
        {properties.map((property, i) => {
          const lastUpdate = property.timestamp ? new Date((property.timestamp || 0) * 1000) : new Date((lastUpdateTimestamp || 0) * 1000)
          let totalLoan = 0
          return (
            <div style={{ marginBottom: "30px" }} key={i}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ height: "50px", backgroundColor: "#4D4D4D", paddingLeft: "19px" }}>
                <Typography.P comp="small-bold" fontColor="#FFFFFF">
                  {property.purpose == "OWNER_OCCUPIED" ? "Home" : "Investment"}
                </Typography.P>
                {!hasActiveEvent &&
                  <LazyLoadImage src={"/images/icon_remove.png"} style={{ height: "50px", cursor: "pointer", color: "#FCB3CD" }} onClick={() => handleRemoveProperty(i)} />
                }
              </Stack>
              <Dialog open={!!isOpen[i]} onClose={() => handleClose(i)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                {/* <DialogTitle id="alert-dialog-title">
                  {"Use Google's location service?"}
                </DialogTitle> */}
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">Are you sure you want to delete this property and its associated loans?</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleClose(i)}>
                    <Typography.P comp="hl-medium-small">Cancel</Typography.P>
                  </Button>
                  <Button onClick={() => removeProperty(i)} autoFocus>
                    <Typography.P comp="hl-medium-small" fontColor="#00AFC5">
                      Confirm
                    </Typography.P>
                  </Button>
                </DialogActions>
              </Dialog>
              <div className={styles.componentDiv}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ marginTop: "4px" }}>
                  <Stack>
                    <Typography.P comp="hl-medium-bold-tight" fontColor="#202020">
                      {property.address1}
                    </Typography.P>
                    <Typography.P comp="hl-medium-tight" fontColor="#202020">
                      {property.address2}
                    </Typography.P>
                  </Stack>
                  {!hasActiveEvent &&
                    <Clickable.Text secondary noBorder onClick={() => handleEditProperty(i)} disabled={hasActiveEvent}>
                      <Typography.P comp="hl-medium-small" fontColor="#00AFC5">
                        Edit
                      </Typography.P>
                    </Clickable.Text>
                  }
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={1} style={{ marginTop: "20px", marginBottom: "14px" }}>
                  <Typography.P comp="profile-small">Estimated Value</Typography.P>
                  <Typography.P comp="small-bold">{"$" + property.value.toLocaleString()}</Typography.P>
                </Stack>
              </div>
              <div className={styles.contentDiv}>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <LazyLoadImage src={providerImage(property.provider.id)} style={{ marginLeft: "-13px", height: "38px" }} />
                    <Typography.P comp="hl-medium">{property.provider.name}</Typography.P>
                  </Stack>
                  {!hasActiveEvent &&
                    <Clickable.Text
                      secondary
                      noBorder
                      style={{ backgroundColor: "inherit" }}
                      disabled={hasActiveEvent}
                      onClick={() => {
                        navigate("/user/update-your-provider", {
                          state: {
                            properties: properties,
                            currentIndex: i,
                          },
                        })
                      }}
                    >
                      <Typography.P comp="hl-medium-small" fontColor="#00AFC5">
                        Update
                      </Typography.P>
                    </Clickable.Text>
                  }
                </Stack>
                {property.loans.map((l, j) => {
                  totalLoan += l.value
                  let expiryDate = null
                  if (l.rateType === "FIXED") {
                    expiryDate = new Date(l.expiryDate)
                  }
                  return (
                    <div style={{ marginBlock: isWide ? "0px" : "10px", marginTop: "30px" }} key={j}>
                      <Stack direction="row" justifyContent="space-between" style={{ marginBottom: "15px" }}>
                        <Typography.P comp="small-bold" fontColor="#202020">
                          {"Loan" + (property.loans.length > 1 ? " #" + (j + 1) : "")}
                        </Typography.P>
                        <Typography.P comp="small-bold" fontColor="#202020">
                          {"$" + l.value.toLocaleString()}
                        </Typography.P>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography.P comp="profile-small" fontColor="#797979">
                          {(l.rateType === "FIXED" ? "Fixed" : "Variable") + " Rate"}
                        </Typography.P>
                        <Typography.P comp="profile-small" fontColor="#797979">
                          {(l.rate * 100).toFixed(2) + "% p.a."}
                        </Typography.P>
                      </Stack>
                      {l.rateType === "FIXED" && expiryDate && (
                        <div>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography.P comp="profile-small" fontColor="#797979">
                              Rate on Expiry
                            </Typography.P>
                            <Typography.P comp="profile-small" fontColor="#797979">
                              {(l.revertRate * 100).toFixed(2) + "% p.a."}
                            </Typography.P>
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography.P comp="profile-small" fontColor="#797979">
                              Expiry Date
                            </Typography.P>
                            <Typography.P comp="profile-small" fontColor="#797979">
                              {monthNames[expiryDate.getMonth()] + " " + expiryDate?.getFullYear()}
                            </Typography.P>
                          </Stack>
                        </div>
                      )}
                      <Stack direction="row" justifyContent="space-between">
                        <Typography.P comp="profile-small" fontColor="#797979">
                          Repaying
                        </Typography.P>
                        <Typography.P comp="profile-small" fontColor="#797979">
                          {l.repaymentType === "PRINCIPAL_AND_INTEREST" ? "Principal & Interest" : "Interest Only"}
                        </Typography.P>
                      </Stack>
                    </div>
                  )
                })}
                <Stack direction="row" justifyContent="space-between" style={{ marginTop: "30px" }}>
                  <Typography.P comp="small-bold" fontColor="#202020">
                    Total Loan
                  </Typography.P>
                  <Typography.P comp="small-bold" fontColor="#202020">
                    {"$" + totalLoan.toLocaleString()}
                  </Typography.P>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography.P comp="profile-small" fontColor="#797979">
                    LVR
                  </Typography.P>
                  <Typography.P comp="profile-small" fontColor="#797979">
                    {Math.round((totalLoan / property.value) * 100) + "%"}
                  </Typography.P>
                </Stack>
                {property.offset && (
                  <div>
                    <Stack direction="row" justifyContent="space-between" style={{ marginTop: "30px" }}>
                      <Typography.P comp="small-bold" fontColor="#202020">
                        Offset Account
                      </Typography.P>
                      <Typography.P comp="small-bold" fontColor="#202020">
                        {"$" + property.offset.toLocaleString()}
                      </Typography.P>
                    </Stack>
                  </div>
                )}
                <Stack direction="row" justifyContent="space-between" style={{ marginTop: "30px" }}>
                  <Typography.P comp="profile-small" fontColor="#797979">
                    Last updated
                  </Typography.P>
                  <Typography.P comp="profile-small" fontColor="#797979">
                    {lastUpdate.toLocaleDateString("en-GB", { day: "numeric", month: "numeric", year: "numeric" })}
                  </Typography.P>
                </Stack>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  // const reduceArray = (inputArray: [], chunkSize: number) => {
  //   const resultArrays = inputArray.reduce((resultArray, item, i) => {
  //     const chunkIndex = Math.floor(i / chunkSize)
  //     resultArray[chunkIndex] = [].concat(resultArray[chunkIndex] || [], item)
  //     return resultArray
  //   }, [])

  //   const padding = Array(chunkSize - resultArrays[resultArrays.length - 1].length).fill(null)
  //   resultArrays[resultArrays.length - 1].push(...padding)

  //   return resultArrays
  // }

  const OffersComponent = () => {
    return (
      <div>
        <Typography.P>Viewed on 8th June, 2023</Typography.P>
        {offers.length > 0 &&
          offers.map((o, i) => {
            const properties = o.offer.properties
            return (
              <div className={styles.componentDiv} key={i}>
                {properties.map((property, j) => {
                  return (
                    <div key={j}>
                      <Stack direction="row" alignItems="center" spacing={0.5} style={{ marginBottom: "10px" }}>
                        <LazyLoadImage style={{ width: "21px", height: "21px" }} src={"/images/icon_home.png"} alt="" />
                        <Typography.P comp="hl-medium-small" fontColor="#696969">
                          {property.address}
                        </Typography.P>
                      </Stack>
                      <Stack direction={isWide ? "row" : "column"} justifyContent="space-between" alignItems="center" spacing={isWide ? 0 : 3}>
                        {property.loans.map((l, k) => {
                          return (
                            <div className={styles.contentDiv} style={{ width: isWide ? "45%" : "90%" }} key={k}>
                              <Stack alignItems="center">
                                <Stack direction="row" alignItems="center" spacing={0.5}>
                                  <LazyLoadImage src={providerImage(property.providerId)} style={{ height: "37.5px" }} />
                                  <Typography.P comp="profile-large-medium" fontColor="#696969">
                                    {property.providerName}
                                  </Typography.P>
                                </Stack>
                                <Typography.P comp="hl-medium" fontColor="#696969">
                                  {l.productName}
                                </Typography.P>
                                <Stack alignItems="center" style={{ marginTop: "20px", marginBottom: "15px" }}>
                                  <Typography.P comp="profile-large-bold" fontColor="#00AFC5">
                                    ${Math.round(l.totalSavings).toLocaleString()}
                                  </Typography.P>
                                  <Typography.P comp="x-small" fontColor="#696969">
                                    estimated savings over 5 years
                                  </Typography.P>
                                </Stack>
                                <Stack direction="row" alignItems="center" spacing={"4px"} style={{ height: "30px", width: "50%", marginBottom: "25px" }}>
                                  <div style={{ display: "flex", height: "22px", padding: "0px 10px", backgroundColor: "#00AFC5", border: "1px solid #00AFC5", borderRadius: "50px" }}>
                                    <Typography.P comp="hl-pill" fontColor="#FFFFFF">
                                      {"Variable"}
                                    </Typography.P>
                                  </div>
                                  <div style={{ display: "flex", height: "22px", padding: "0px 10px", backgroundColor: "#00AFC5", border: "1px solid #00AFC5", borderRadius: "50px" }}>
                                    <Typography.P comp="hl-pill" fontColor="#FFFFFF">
                                      {"P&I"}
                                    </Typography.P>
                                  </div>
                                </Stack>
                                <Stack direction="row" justifyContent="space-around" style={{ width: "80%", marginBottom: "35px" }}>
                                  <Stack>
                                    <Typography.P comp="hl-medium-bold-tight">{(l.interestRate * 100).toFixed(2)}%</Typography.P>
                                    <Typography.P comp="x-small">
                                      interest <br /> rate p.a.
                                    </Typography.P>
                                  </Stack>
                                  <Stack>
                                    <Typography.P comp="hl-medium-bold-tight">{(l.comparisonRate * 100).toFixed(2)}%</Typography.P>
                                    <Typography.P comp="x-small">
                                      comparison <br /> rate p.a.
                                    </Typography.P>
                                  </Stack>
                                </Stack>
                                <Clickable.Text secondary style={{ backgroundColor: "inherit", width: "50%", height: "40px", marginBottom: "10px" }} onClick={() => { }}>
                                  <Typography.P comp="hl-medium-small" fontColor="#00AFC5">
                                    Learn more
                                  </Typography.P>
                                </Clickable.Text>
                              </Stack>
                            </div>
                          )
                        })}
                      </Stack>
                    </div>
                  )
                })}
              </div>
            )
          })}
      </div>
    )
  }

  const DashboardSection = ({ title, children, buttonText, onButtonClick, noBottomMargin }: {
    title?: string,
    children?: React.ReactNode,
    buttonText?: string,
    onButtonClick?: () => any,
    noBottomMargin?: boolean
  }) => {
    return <Stack marginBottom={noBottomMargin ? 0 : "40px"}>
      {(title || buttonText) && <Stack direction="row" justifyContent="space-between" alignItems="flex-end" marginBottom={"30px"}>
        {title && <Typography.P comp="profile-large-bold" fontColor="#202020">
          {title}
        </Typography.P>}
        {buttonText && <Clickable.Text
          secondary
          noBorder
          onClick={onButtonClick}
        >
          <Typography.P comp="hl-medium-small" fontColor="#00AFC5" style={{ padding: "2px 6px" }}>
            {buttonText}
          </Typography.P>
        </Clickable.Text>}
      </Stack>}
      {children}
    </Stack>
  }

  return (
    <div className="page-container">
      <div style={{ width: isWide ? "480px" : "90%" }}>
        {(userDetailsLoadStatus === "success") ? <>
          {/* <DashboardSection title='Notifications' buttonText="Preferences" onButtonClick={() => {}}>
            {notificationLoadStatus === 'success' ? topNotifications.length > 0 ? <>
              {topNotifications.map((n, i) => <UserNotification notification={n} key={`notification-${i}`} />)}
              <Clickable.Text
                style={{ height: "56px" }}
                onClick={() => navigate("/user/notifications")}
              >
                <Typography.P comp="medium-bold-tight" fontColor="#FFFFFF">
                  See all notifications{newNotificationCount > 0 ? ` (${newNotificationCount} new)` : ''}
                </Typography.P>
              </Clickable.Text>
            </> : <>
              <Typography.P style={{ marginBottom: "24px" }}>
                No notifications to display.
              </Typography.P>
              <Clickable.Text
                style={{ height: "56px" }}
                onClick={() => navigate("/user/notifications")}
              >
                <Typography.P comp="medium-bold-tight" fontColor="#FFFFFF">
                  See all notifications
                </Typography.P>
              </Clickable.Text>
            </> : notificationLoadStatus === 'error' ? <Typography.P>
              An error has occurred while loading notifications. Please try reloading the page.
            </Typography.P> : <CircularProgress style={{color: "#00AFC5"}} />}
          </DashboardSection> */}
          <DashboardSection title='Your details' buttonText="Sign out" onButtonClick={handleSignout}>
            <DetailsComponent />
          </DashboardSection>
          {/* <DashboardSection title="Your home loans" noBottomMargin={offers.length === 0 && retentionEvents?.length === 0}> */}
          <DashboardSection title="Your home loans" noBottomMargin={offers.length === 0}>
            {propertiesLoadStatus === "success" ? <>
              {properties.length > 0 ? <ProductsComponent /> : <Typography.P style={{ marginBottom: "24px" }}>
                No home loans to display.
              </Typography.P>}
              {!hasActiveEvent && (
                <Clickable.Text
                  comp={3}
                  disabled={false}
                  className="survey-button"
                  style={{ marginTop: "0px", backgroundColor: "#EEF4F5", color: "#202020" }}
                  onClick={() => {
                    navigate("/user/update-your-property", {
                      state: {
                        properties: properties,
                        currentIndex: properties.length,
                      },
                    })
                  }}
                >
                  + Add property
                </Clickable.Text>
              )}
            </> : propertiesLoadStatus === "loading" ? <CircularProgress style={{ color: "#00AFC5" }} /> : <Typography.P>
              An error has occurred while loading notifications. Please try reloading the page.
            </Typography.P>}
          </DashboardSection>
          {/* <DashboardSection title='Viewed offers' noBottomMargin>
            <OffersComponent />
          </DashboardSection> */}
          {/* {offers.length > 0 && <DashboardSection noBottomMargin={retentionEvents?.length === 0}> */}
          {offers.length > 0 && <DashboardSection>
            <Clickable.Text
              style={{
                height: "56px",
                marginTop: "60px",
              }}
              onClick={() => {
                API.startSurvey(responseJson => {
                  store.dispatch(storeSurveyId(responseJson.surveyId))
                  navigate("/home-loans/offers-summary", {
                    state: { jsonInput: { properties: properties }, progress: 5, results: undefined, source: "DASHBOARD" },
                  })
                })
              }}
            >
              <Typography.P comp="medium-bold-tight" fontColor="#FFFFFF">
                View latest offers
              </Typography.P>
            </Clickable.Text>
          </DashboardSection>}
          {/* <DashboardSection title='History'>
            {eventHistoryLoadStatus === 'success' ? eventHistory.length > 0 ? <Stack>
              {eventHistory.map((e, i) => <EventSummary eventSummary={e} key={i} />)}
              <Clickable.Text
                style={{ height: "56px" }}
                onClick={() => navigate("/user/history")}
              >
                <Typography.P comp="medium-bold-tight" fontColor="#FFFFFF">
                  See all
                </Typography.P>
              </Clickable.Text>
            </Stack> : <Typography.P>
              No historical event outcomes to display.
            </Typography.P> : eventHistoryLoadStatus === 'error' ? <Typography.P>
              An error has occurred while loading the event history. Please try reloading the page.
            </Typography.P> : <CircularProgress style={{ color: "#00AFC5" }} />}
          </DashboardSection>
          {(retentionEvents?.length > 0) && <DashboardSection title="HL Retention Events">
            {retentionEvents.map((re, ri) => {
              return (
                <React.Fragment key={ri}>
                  {
                    re.properties.map((p, i) => {
                      return (
                        <React.Fragment key={i}>
                          <Typography.P>Event ID: {re.eventId}</Typography.P>
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography.P>{p.address1 + ", " + p.address2}</Typography.P>
                            <Stack direction="row" alignItems="center" spacing={0.5}>
                              <LazyLoadImage src={providerImage(re.properties[0].provider.id)} style={{ height: "37.5px" }} />
                            </Stack>
                          </Stack>
                          {re?.offers?.loans?.length > 0 && (
                            <Stack>
                              {p.loans.map((l, j) => {
                                return (
                                  <React.Fragment key={j}>
                                    <Typography.P>Loan {j + 1}:</Typography.P>
                                    <Stack direction="row" justifyContent="space-evenly">
                                      <Typography.P>Offered Rate:</Typography.P>
                                      <Typography.P>{re.offers.loans[i][j].rate * 100}%</Typography.P>
                                    </Stack>
                                    <Stack direction="row" justifyContent="space-evenly">
                                      <Typography.P>Estimated Savings:</Typography.P>
                                      <Typography.P>{re.offers.estimatedSavings ? ("$" + re.offers.estimatedSavings[i + j]) : "N/A"}</Typography.P>
                                    </Stack>
                                  </React.Fragment>
                                )
                              })}
                            </Stack>
                          )}
                          <Stack direction="row" justifyContent="space-between">
                            <Typography.P>Status:</Typography.P>
                            <Typography.P>{re.status}</Typography.P>
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography.P>Fulfilment:</Typography.P>
                            <Typography.P>{re.offers.fulfilment}</Typography.P>
                          </Stack>
                        </React.Fragment>
                      )
                    })
                  }
                  {re.status === "AWAITING_CUSTOMER" && (
                    <Stack direction="row" justifyContent="space-evenly">
                      <Button onClick={() => acceptOffer(re.eventId)}>accept</Button>
                      <Button>decline</Button>
                    </Stack>
                  )}
                </React.Fragment>
              )
            })}
          </DashboardSection>} */}
        </> : userDetailsLoadStatus === "error" ? <Typography.P>
          An error has occurred. You will be returned to the sign-in page.
        </Typography.P> : <CircularProgress style={{ color: "#00AFC5" }} />}
      </div>
    </div>
  )
}

export default Dashboard
